<template>
<div>
  <compact-list :payload="compactListPayload" :total="total"></compact-list>
   <v-dialog v-model="dialog" width="700" persistent>
     <v-card>
       <v-card-title>{{ $t('editMember') }}</v-card-title>
       <v-divider></v-divider>
       <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12" lg="12">
              <v-select v-model="memberInfo.user_id" v-if="memberInfo.user_id" outlined dense hide-details full-width :items="getUsers" item-text="name" item-value="_id" disabled></v-select>
              <v-select v-model="memberInfo.contact_id" v-if="memberInfo.contact_id" outlined dense hide-details full-width :items="listOfAllContacts" item-text="data.name" item-value="_id" disabled></v-select>
            </v-col>
            <v-col cols="12" lg="12">
              <v-select v-model="memberInfo.role_id" v-if="memberInfo.role_id" :items="listOfRoles" outlined dense hide-details full-width item-text="name" item-value="_id"></v-select>
            </v-col>
           </v-row>
         </v-form>
       </v-card-text>
       <v-divider></v-divider>
       <v-card-actions>
         <v-flex class="text-center">
           <v-btn color="success" class="mr-3" @click="updateMember()">{{ $t('update') }}</v-btn>
           <v-btn color="error" @click="dialog = false">{{ $t('close') }}</v-btn>
         </v-flex>
       </v-card-actions>
     </v-card>
   </v-dialog>
   <v-dialog v-model="createDialog" persistent width="800px" fullscreen>
      <v-card>
        <v-toolbar dark color="primary" class="mb-1">
          <v-icon @click="closeCreateDialogHandler()">mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('addProjectMembers') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="px-1">
          <v-tabs fixed-tabs  dark v-model="active">
             <v-tab  v-for="i in tabs" :key="i" :href="`#tab-${i}`"> {{ $t(i) }}</v-tab>
              <v-tab-item v-for="i in tabs" :key="i" :value="'tab-' + i">
                <v-row>
                  <v-col cols="12" lg="12"  v-if="i !== 'employee'">
                     <v-autocomplete dense outlined hide-details full-width v-model="companyId" :items="listofAccounts"  item-value="_id" item-text="data.name" :label="$t('selectCompany')" :loading="loadingSelect"></v-autocomplete>
                  </v-col>
                   <!-- <v-col cols="12" lg="8"  v-if="i !== 'Employee'"></v-col> -->
                  <v-col cols="12" lg="5" class="pb-1">
                    <template  v-if="i === 'employee'">
                      <v-card outlined>
                        <v-card-text class="pa-0">
                          <v-list class="list-scroll">
                            <template v-for="(item, i) in usersList">
                              <v-list-item  :style="selectedEmployee.some(val => val._id === item._id) ? `background:#80808047` : `background:inherit`" :key="i" @click="pushorPopHandler(item)">
                                <v-list-item-title v-text=" item.lastname ? `${item.firstname} ${item.lastname}` : item.firstname" class="body-2"></v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </template>
                    <template  v-else>
                      <v-card outlined>
                        <v-card-text class="pa-0">
                          <v-list class="list-scroll">
                            <template v-for="(item, i) in listOfContacts">
                              <v-list-item  :style="selectedContact.some(val => val._id === item._id) ? `background:#80808047` : `background:inherit`" :key="i" @click="pushorPopContactHandler(item)">
                                <v-list-item-title v-text="item.data.name" class="pa-0"></v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-col>
                  <v-col cols="12" lg="1" class="text-center py-0">
                    <v-btn color="primary" class="white--text" fab x-small @click="moveData(i)">
                      <v-icon dark>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-0 pt-1">
                    <template  v-if="i === 'employee'">
                      <v-card outlined>
                        <v-card-text>
                          <div v-if="finalSelectedEmployees.length > 0" class="list-scroll">
                          <template >
                            <v-row v-for="(employee, index) in finalSelectedEmployees" :key="index">
                              <v-col cols="12" lg="5" class="py-1">
                                {{ employee.lastname ? `${employee.firstname} ${employee.lastname}` : employee.firstname }}
                              </v-col>
                            </v-row>
                          </template>
                          </div>
                            <div v-else class="list-scroll">
                              {{ $t('noData') }}
                            </div>
                        </v-card-text>
                      </v-card>
                    </template>
                    <template  v-else>
                      <v-card outlined>
                        <v-card-text>
                          <div v-if="finalSelectedContacts.length > 0" class="list-scroll">
                            <v-row v-for="(contact, index) in finalSelectedContacts" :key="index">
                              <v-col cols="12" lg="5" class="py-1">
                                {{ contact.data.name }}
                              </v-col>
                            </v-row>
                          </div>
                          <div v-else class="list-scroll">
                            {{ $t('noData') }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-col>
                  <!-- <v-col cols="12" lg="6"></v-col> -->
                  <v-col cols="12" lg="6">
                    <v-select dense outlined hide-details full-width v-model="role_id" :items="listOfRoles" item-value="_id" item-text="name" :label="$t('selectRole')"></v-select>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-flex class="text-center">
                  <v-btn class="ma-2" color="success" @click="saveHandler(i)" v-if="active === 'tab-employee'" :disabled="finalSelectedEmployees.length === 0 || !role_id">{{ $t('save') }}</v-btn>
                  <v-btn class="ma-2" color="success" @click="saveHandler(i)" v-else :disabled="finalSelectedContacts.length === 0 || !role_id">{{ $t('save') }}</v-btn>
                </v-flex>
              </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import CompactList from '../../components/CompactList'
export default {
  props: ['hasPermissionTo'],
  data () {
    return {
      dialog: false,
      loading: false,
      active: 'tab-employee',
      createDialog: false,
      membersTitle: '',
      selected: [],
      selectedEmployee: [],
      selectedContact: [],
      finalSelectedEmployees: [],
      finalSelectedContacts: [],
      listOfContacts: [],
      listOfAllContacts: [],
      listofAccounts: [],
      moduleObj: {
        moduleId: this.$route.params.id,
        moduleName: this.$route.params.name,
        recordId: this.$route.params.record_id
      },
      MODULEURL: 'projectmembers',
      tabs: ['employee', 'contact'],
      fromApp: false,
      listOfRoles: [],
      memberInfo: [],
      usersList: [],
      companyId: 0,
      role_id: '',
      loadingSelect: false,
      compactListPayload: {
        showBackBtn: () => this.$parent.removeActiveModule(),
        hideHeader: false,
        addClickHandler: this.openCreateDialogHandler,
        editHandler: () => {}, // (item) => this.getMemberHandler(item._id),
        refreshHandler: this.getMemberListHandler,
        moduleTitle: 'Members',
        hasNoNext: false,
        loading: false,
        list: [],
        pagination: {},
        storeIndex: '',
        items: [{ value: 'name' }, { value: 'role_name' }],
        hasFilter: false,
        isCustomModule: false, // flag to show the relate unrelate options
        moduleIcon: 'mdi-account-multiple-plus',
        hasPermission: false
      },
      pagination: { itemsPerPage: 8, page: 1 },
      deleteSuccessPagination: null,
      total: 0
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'userDetails', 'listOfModules']),
    headers () {
      return [{
        text: this.$t('name'),
        value: 'name',
        width: '50%'
      }, {
        text: this.$t('role'),
        value: 'role_name',
        width: '50%'
      }]
    }
  },
  components: {
    'compact-list': CompactList
  },
  watch: {
    companyId (val) {
      if (val) {
        this.getContactNameBasedOnCompany(val)
      }
    },
    '$store.state.common.listOfModules' (val) {
      this.setPermission()
    }
  },
  created () {
    this.$store.dispatch('getUsers')
    this.$api.execute('get', 'moduledata/Account').then(response => {
      this.compactListPayload.loading = true
      this.listofAccounts = response.data
      this.$api.execute('get', 'moduledata/Contact').then(response => {
        this.listOfAllContacts = response.data
        const query = [{ $match: { IsActive: true } }]
        this.$api.execute('get', `${this.MODULEURL}/get_unassigned_users/${this.moduleObj.recordId}`).then(response => {
          this.usersList = response.data
          this.$api.execute('post', 'projectroles/query', query).then(response => {
            this.listOfRoles = response.data
            this.getMemberListHandler()
          })
        })
      })
    })
  },
  mounted () {
    this.setPermission()
    const text = 'deleteMsg'
    this.$eventBus.$on('loadList', (data) => {
      this.getMemberListHandler(data)
    })
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.MODULEURL) {
        this.deleteSuccessPagination = data.pagination
        const payload = { ids: [data.id], url: `${this.MODULEURL}/delete_multiple`, module: this.MODULEURL }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULEURL) {
        this.selected = []
        this.pagination = this.deleteSuccessPagination
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.getMemberListHandler()
        this.$api.execute('get', `${this.MODULEURL}/get_unassigned_users/${this.moduleObj.recordId}`).then(response => {
          this.usersList = response.data
        })
      }
    })
  },
  methods: {
    addToSelected (event, item) {
      if (event) {
        this.selected.push(item)
      } else {
        const result = this.selected.filter(x => x._id !== item._id)
        if (result) {
          this.selected = result
        }
      }
    },
    pushorPopHandler (item) {
      const result = this.selectedEmployee.find(x => x._id === item._id)
      if (result) {
        const index = this.selectedEmployee.findIndex(x => x._id === item._id)
        this.selectedEmployee.splice(index, 1)
      } else this.selectedEmployee.push(item)
    },
    pushorPopContactHandler (item) {
      const result = this.selectedContact.find(x => x._id === item._id)
      if (result) {
        const index = this.selectedContact.findIndex(x => x._id === item._id)
        this.selectedContact.splice(index, 1)
      } else this.selectedContact.push(item)
    },
    moveData (tabName) {
      if (tabName === 'employee') {
        this.finalSelectedEmployees = this.$formatter.cloneVariable(this.selectedEmployee)
      } else {
        this.finalSelectedContacts = this.$formatter.cloneVariable(this.selectedContact)
      }
    },
    saveHandler (i) {
      if (this.role_id) {
        const model = []
        if (i === 'employee') {
          const employeeList = this.$formatter.cloneVariable(this.finalSelectedEmployees)
          employeeList.forEach(element => {
            model.push({ user_id: element._id, role_id: this.role_id })
          })
        } else {
          const contactList = this.$formatter.cloneVariable(this.finalSelectedContacts)
          contactList.forEach(element => {
            model.push({ contact_id: element._id, role_id: this.role_id })
          })
        }
        this.$api.execute('post', `${this.MODULEURL}/bulk_save/${this.moduleObj.recordId}`, model).then(response => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'savedSuccess' })
          this.$api.execute('get', `${this.MODULEURL}/get_unassigned_users/${this.moduleObj.recordId}`).then(response => {
            this.usersList = response.data
          })
          this.getMemberListHandler()
          this.closeCreateDialogHandler()
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'Please select Role!', isException: true })
    },
    getContactNameBasedOnCompany (companyId) {
      this.loadingSelect = true
      this.$api.execute('get', `${this.MODULEURL}/get_unassigned_contacts/${this.moduleObj.recordId}/${companyId}`).then(response => {
        this.listOfContacts = response.data
        this.loadingSelect = false
      })
    },
    updateMember () {
      this.$api.execute('put', `${this.MODULEURL}/${this.memberInfo._id}`, this.memberInfo).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
        this.dialog = false
        this.getMemberListHandler()
      })
    },
    getMemberListHandler (paginationObj) {
      this.compactListPayload.module = this.MODULEURL
      const pagination = paginationObj && paginationObj.itemsPerPage ? paginationObj : this.pagination
      this.compactListPayload.pagination.page = pagination.page
      this.pagination.page = pagination.page
      const pageVal = pagination.page
      const skipVal = ((pageVal - 1) * pagination.itemsPerPage)
      const query = [
        { $match: { $and: [{ Project_Id: { $eq: this.moduleObj.recordId } }] } },
        { $facet: { data: [{ $skip: skipVal || 0 }], count: [{ $count: 'count' }] } } /* , { $limit: pagination.itemsPerPage } */
      ]
      this.$api.execute('post', `${this.MODULEURL}/query`, query).then(response => {
        const { data, count } = response.data[0]
        data.forEach(element => {
          element.name = ''
          element.role_name = ''
          if (element.user_id) element.name = this.getEmployeeName(element.user_id)
          if (element.contact_id) element.name = this.getContactName(element.contact_id)
          element.role_name = this.getRoleName(element.role_id)
        })
        this.compactListPayload.list = data
        this.total = count.length > 0 ? count[0].count : 0
        const totalCount = Math.ceil(this.total / pagination.itemsPerPage)
        if (this.pagination.page === totalCount) this.compactListPayload.hasNoNext = true
        else this.compactListPayload.hasNoNext = false
        if (totalCount === 0) this.compactListPayload.hasNoNext = true
        const getCountParams = { totalCount, page: this.pagination.page, actionDelete: this.pagination.actionDelete }
        this.$eventBus.$emit('getCount', getCountParams)
        this.pagination.actionDelete = undefined
      }).finally(() => {
        this.compactListPayload.loading = false
      })
    },
    getEmployeeName (id) {
      const result = this.getUsers.find(x => x._id === id)
      if (result) return result.name
    },
    getContactName (id) {
      const result = this.listOfAllContacts.find(x => x._id === id)
      if (result) return result.data.name
    },
    getRoleName (id) {
      const result = this.listOfRoles.find(x => x._id === id)
      if (result) return result.name
    },
    closeCreateDialogHandler () {
      this.createDialog = false
      this.selectedContact = []
      this.selectedEmployee = []
      this.companyId = 0
      this.role_id = ''
      this.listOfContacts = []
      this.finalSelectedEmployees = []
      this.finalSelectedContacts = []
    },
    openCreateDialogHandler () {
      // set role_id of default role
      const item = this.listOfRoles.find(x => x.isdefault)
      if (item) this.role_id = item._id
      this.createDialog = true
    },
    getMemberHandler (id) {
      this.$api.execute('get', `${this.MODULEURL}/${id}`).then(response => {
        this.memberInfo = response.data
        this.dialog = true
      })
    },
    deleteHandler () {
      const ids = this.selected.map(x => x._id)
      const data = { module: this.MODULEURL, ids }
      this.$eventBus.$emit('deleteItems', data)
    },
    setPermission () {
      const hasManagePermission = this.$formatter.permissionSetting(this.listOfModules, this.moduleObj.moduleId, this.userDetails)
      this.compactListPayload.hasPermission = hasManagePermission && (this.hasPermissionTo && this.hasPermissionTo !== this.VIEW)
      this.compactListPayload.editHandler = this.compactListPayload.hasPermission ? (item) => this.getMemberHandler(item._id) : () => {}
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('loadList')
  }
}
</script>
<style scoped>
.overflow-td {
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.checkboxPadding .v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.v-list-item {
  min-height: 25px !important;
}
.list-scroll {
  height: 150px;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
